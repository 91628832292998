<template>
  <h1 class="title">News</h1>

</template>

<script setup></script>

<style lang="less" scoped>
.grid-box {
  display: grid;
  grid-template-columns: 5fr 3fr;
  padding: 40px 0;
}
.left-wrapper {
  grid-column: 2fr;
  margin-right: 30px;
  .text {
    margin: 30px 0 12px;
    font-size: 16px;
    text-indent: 2em;
  }
}
.img {
  border-radius: 8px;
  width: 100%;
}
</style>
