<template>
  <div class="c_wrapper">
    <div class="c_content">
      <slot></slot>
    </div>
  </div>
</template>

<script setup></script>

<style lang="less" scoped>
.c_wrapper {
  box-sizing: border-box;
  border: 1px solid rgb(229 229 229);
  width: 100%;
  height: 100%;
  background-color: #fff;
  border-radius: 0.5em;
  padding: 12px;
  .c_content {
    width: 100%;
    height: 100%;
    text-align: center;
  }
  &:hover {
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
    .c_content {
      transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
      transform: scaleX(1.05) scaleY(1.05);
    }
  }
}
</style>
