import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { ElCarousel, ElImage, ElButton, ElMenu, ElCollapse, ElCard, ElTag, ElDivider, ElInput, ElMessage } from 'element-plus';
import 'element-plus/dist/index.css'
import './assets/common.css'
import { createPinia } from 'pinia'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

const app = createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}

const pinia = createPinia()
app
    .use(ElCarousel)
    .use(ElImage)
    .use(ElButton)
    .use(ElMenu)
    .use(ElCollapse)
    .use(ElCard)
    .use(ElTag)
    .use(ElDivider)
    .use(ElInput)
    .use(ElMessage)
    .use(pinia).use(router).mount('#app')