<template>
  <h1 class="title">Contact Us</h1>
  <el-card>
    <div class="flex-box">
      <div class="left-wrapper">
        <div class="text">Thanks for your attention.</div>
        <h1>Yiwu Yichi Wire Mesh Company</h1>
        <el-divider></el-divider>
        <div class="row">
          <span><Location style="width: 20px; height: 20px" /></span>
          <span class="label">Address：</span>
          <span
            >Building 16, Huangyuan New Village, Choucheng Street, Yiwu City, Jinhua,
            Zhejiang Province, China.</span
          >
        </div>
        <div class="row">
          <span><PhoneFilled style="width: 20px; height: 20px" /></span>
          <span class="label">Tel：</span>
          <span>+86 19136215806</span>
        </div>
        <div class="row">
          <span
            ><svg
              t="1735714314615"
              class="icon"
              viewBox="0 0 1024 1024"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              p-id="1541"
              width="20"
              height="20"
            >
              <path
                d="M746.581333 613.632c-12.842667-6.4-75.392-36.992-87.04-41.216-11.648-4.309333-20.181333-6.4-28.714666 6.4-8.405333 12.586667-32.896 41.130667-40.277334 49.578667-7.466667 8.32-14.890667 8.96-27.562666 3.2-12.8-6.4-53.888-19.84-102.528-63.36-37.888-33.92-63.317333-75.52-70.826667-88.32-7.424-12.8-0.810667-19.84 5.546667-26.24 5.802667-5.76 12.842667-14.72 19.242666-22.314667 6.229333-7.722667 8.277333-12.842667 12.672-21.162667 4.266667-8.96 2.090667-16-1.066666-22.357333-3.2-6.4-28.672-69.12-39.338667-94.122667-10.24-24.917333-20.778667-21.76-28.672-21.76-7.338667-0.64-15.829333-0.64-24.362667-0.64-8.533333 0-22.314667 3.157333-34.005333 15.317334-11.648 12.8-44.586667 43.52-44.586667 105.6s45.653333 122.24 52.010667 131.2c6.357333 8.32 89.813333 136.32 217.6 191.36 30.464 12.8 54.186667 20.48 72.704 26.837333 30.464 9.685333 58.24 8.32 80.213333 5.162667 24.490667-3.882667 75.392-30.762667 86.016-60.842667 10.88-30.08 10.88-55.04 7.68-60.8-3.157333-5.76-11.52-8.96-24.32-14.72m-232.362666 317.568h-0.682667c-75.52 0-150.357333-20.48-215.68-58.88l-15.36-9.130667-160 41.6 42.88-155.52-10.197333-16a421.077333 421.077333 0 0 1-64.682667-224.426666c0-232.32 190.08-421.76 424.192-421.76a420.906667 420.906667 0 0 1 299.562667 124.16 417.621333 417.621333 0 0 1 124.117333 298.24c-0.170667 232.277333-190.293333 421.76-423.893333 421.76M875.52 147.157333C778.24 53.12 650.24 0 513.92 0 233.088 0 4.437333 227.584 4.309333 507.434667c0 89.429333 23.424 176.64 68.053334 253.653333L0 1024l270.293333-70.485333a514.645333 514.645333 0 0 0 243.626667 61.738666h0.256c280.96 0 509.696-227.669333 509.824-507.562666 0-135.509333-52.906667-263.04-149.12-358.869334"
                fill="#000"
                p-id="1542"
              ></path></svg
          ></span>
          <span class="label">WHATSAPP：</span>
          <span>+86 19136215806</span>
        </div>
        <div class="row">
          <span><Message style="width: 20px; height: 20px" /></span>
          <span class="label">Email：</span>
          <span>jenny@yichiwiremesh.com</span>
        </div>
      </div>
      <div class="right-wrapper">
        <img class="img" src="static/contactUS/bg.webp" alt="image" />
      </div>
    </div>
  </el-card>

  <h1 class="title">Info</h1>
  <p class="note">
    If you have any suggestions or questions for us, please feel free to contact us.
  </p>
  <el-card>
    <div class="from-row">
      <span class="from-label"><i class="notNull">*</i>Company:</span
      ><el-input v-model="company" placeholder="Please input " />
    </div>
    <div class="from-row">
      <span class="from-label"><i class="notNull">*</i>Email:</span
      ><el-input v-model="email" placeholder="Please input " />
    </div>
    <div class="from-row">
      <span class="from-label"><i class="notNull">*</i>Subject:</span
      ><el-input v-model="subject" placeholder="Please input " />
    </div>
    <div class="from-row">
      <el-input
        v-model="desc"
        :rows="6"
        type="textarea"
        placeholder="If you have any suggestions or questions for us, please feel free to contact us."
      />
    </div>
    <div class="from-row tex-center">
      <el-button type="primary" size="large" @click="handleclick">submit</el-button>
    </div>
  </el-card>
</template>

<script setup>
import { ref } from "vue";
import request from "@/utils/request.js";
import { ElMessage } from "element-plus";

const company = ref();

const email = ref();

const subject = ref();

const desc = ref();

const handleclick = async () => {
  if (!company.value || !email.value || !subject.value || !desc.value) {
    ElMessage({
      message: "Please fill in all the required information.",
      type: "warning",
      plain: true,
    });
    return;
  }
  request
    .post("/api/handleMessage", {
      company: company.value,
      email: email.value,
      subject: subject.value,
      desc: desc.value,
    })
    .then(() => {
      ElMessage({
        message: "Form submitted successfully. We will reach out to you soon.",
        type: "success",
        plain: true,
      });
    });
};
</script>

<style lang="less" scoped>
.flex-box {
  display: flex;
  padding: 40px 0;
}
.left-wrapper {
  margin-right: 30px;
  .text {
    margin: 30px 0 12px;
    font-size: 20px;
  }
}
.img {
  border-radius: 8px;
}
.row {
  margin: 16px 0;
  display: flex;
  span:nth-child(1) {
    padding: 2px 6px;
  }
}
.label {
  margin-right: 4px;
  display: inline-block;
}
.note {
  font-size: 14px;
  color: #6c6969;
  margin-bottom: 16px;
}
.from-row {
  margin-bottom: 16px;
  .from-label {
    display: inline-block;
    margin-bottom: 6px;
  }
}
.notNull {
  color: red;
  margin-right: 4px;
}
.tex-center {
  text-align: end;
}
</style>
