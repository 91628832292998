<template>
  <nav class="header-nav">
    <span class="logo" @click="handleToHome"></span
    ><span class="logo-name" @click="handleToHome">Yiwu Yichi Wire Mesh Company</span>
    <menu class="menu">
      <el-menu
        :default-active="1"
        class="el-menu-demo"
        mode="horizontal"
        @select="handleSelect"
        background-color="#2d2d2d"
        text-color="#fff"
        active-text-color="#fcac21"
        style="width: 100%; background-color: "
      >
        <el-menu-item index="1">
          <RouterLink to="/index">Home</RouterLink>
        </el-menu-item>
        <el-sub-menu index="2">
          <template #title>Product Center</template>
          <el-menu-item
            v-for="item of productsKinds"
            :index="item.key"
            @click="handleClick(item)"
            :key="item.key"
            >{{ item.name }}</el-menu-item
          >
        </el-sub-menu>
        <el-menu-item index="3">
          <RouterLink to="/aboutUs">About Us</RouterLink>
        </el-menu-item>
        <!-- <el-menu-item index="4">
          <RouterLink to="/newsInfo">News</RouterLink>
        </el-menu-item> -->
        <el-menu-item index="5">
          <RouterLink to="/contactUs">Contact Us</RouterLink>
        </el-menu-item>
      </el-menu>
    </menu>
  </nav>
</template>

<script setup>
import { useRouter } from "vue-router";
import { productsKinds } from "./config";

const router = useRouter();

const handleToHome = () => {
  router.push("/index");
};

const handleClick = (item) => {
  router.push({
    path: "/productCenter",
    query: {
      id: item.id,
    },
  });
};
</script>

<style lang="less" scoped>
.header-nav {
  display: flex;
  align-items: center;
  padding: 16px;
  background: #2d2d2d;
  color: #fff;
  border-bottom: 8px solid #fcac21;
  position: sticky;
  top: 0;
  z-index: 10;
  .logo {
    display: inline-block;
    width: 50px;
    height: 50px;
    background: url("@/assets/img/logo.png") no-repeat center;
    background-size: 100%;
    border: 1px solid #e5e5e5;
    border-radius: 8px;
  background-color: #fff;
  margin-right: 12px;
  }
  .logo-name {
    font-weight: 500;
    font-size: 24px;
    margin-right: 8px;
    cursor: pointer;

    &:hover {
      color: #fcac21;
      text-decoration: underline;
    }
  }

  .menu {
    color: #fff;
    font-size: 16px;
    margin-left: 40px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    flex: 1;

    :deep(a) {
      color: #fff;
      text-decoration: none;
      &:hover {
        color: #fcac21;
      }
    }
  }
}
:deep(.el-menu--horizontal.el-menu) {
  border: none;
}
</style>
<style>
.el-popper.is-light {
  border: none !important;
}
</style>
