/**不锈钢筛网 Stainless Steel Screen */
const stainlessSteelScreen = [
    {
        "id": 1,
        "name": "Stainless Steel Mesh",
        "nameCH": '不锈钢编织',
        "Material": 'Stainless Steel',
        "ProcessingMode": 'Woven',
        "No": 'YC001',
        "Type": 'Woven net',
        "ProductCharacteristics": 'Stainless steel mesh products have the characteristics of corrosion resistance, high strength, easy to clean and long service life.',
        "Size": [
            'Width 1000mm-Length 30m',
            'Width 1200mm-Length 30m',
            'Width 1500mm-Length 30m',
            'Width 1800mm-Length 30m',
            'Width 2000mm-Length 30m'
        ],
        "ProductUse": 'Stainless steel mesh is widely used in screening, filtration, protection, architectural decoration and other fields, because of its good corrosion resistance and durability and is favored.',
        "Supply": 'Stock or customized',
        "picUrl": [
            '/static/stainlessSteelScreen/StainlessSteelMesh/1.jpg',
            '/static/stainlessSteelScreen/StainlessSteelMesh/2.jpg',
            '/static/stainlessSteelScreen/StainlessSteelMesh/3.jpg',
            '/static/stainlessSteelScreen/StainlessSteelMesh/4.png',
            '/static/stainlessSteelScreen/StainlessSteelMesh/5.png',
            '/static/stainlessSteelScreen/StainlessSteelMesh/6.png',
            '/static/stainlessSteelScreen/StainlessSteelMesh/7.webp',

        ]
    },
    {
        "id": 2,
        "name": "Stainless Steel Mat Mesh",
        "nameCH": '不锈钢席型网',
        "Material": 'Stainless Steel',
        "ProcessingMode": 'Woven',
        "No": 'YC001',
        "Type": 'Woven net',
        "ProductCharacteristics": 'Stainless steel mesh products have the characteristics of corrosion resistance, high strength, easy to clean and long service life.',
        "Size": [
            'Width 1000mm-Length 30m',
            'Width 1200mm-Length 30m',
            'Width 1500mm-Length 30m',
            'Width 1800mm-Length 30m',
            'Width 2000mm-Length 30m'
        ],
        "ProductUse": 'Stainless steel mesh is widely used in screening, filtration, protection, architectural decoration and other fields, because of its good corrosion resistance and durability and is favored.',
        "Supply": 'Stock or customized',
        "picUrl": [
            '/static/stainlessSteelScreen/StainlessSteelMatMesh/1.jpg',
            '/static/stainlessSteelScreen/StainlessSteelMatMesh/2.png',
            '/static/stainlessSteelScreen/StainlessSteelMatMesh/3.png',
            '/static/stainlessSteelScreen/StainlessSteelMatMesh/4.png',
            '/static/stainlessSteelScreen/StainlessSteelMatMesh/5.png',
        ]
    },
    {
        "id": 3,
        "name": "Stainless Steel Welding Mesh",
        "nameCH": '不锈钢电焊网',
        "Material": 'Stainless Steel',
        "ProcessingMode": 'Weld',
        "No": 'YC001',
        "Type": 'Weld net',
        "ProductCharacteristics": 'Stainless steel welding mesh, made of high-quality stainless steel materials, has the characteristics of corrosion resistance, stable structure, changeable shape and long-term use without frequent maintenance. It is a widely used, economical and durable metal mesh product.',
        "Size": [
            'Width 1000mm-Length 30m',
            'Width 1200mm-Length 30m',
            'Width 1500mm-Length 30m',
            'Width 1800mm-Length 30m',
            'Width 2000mm-Length 30m'
        ],
        "ProductUse": 'Stainless steel welding mesh is widely used in the fields of building reinforcement, fence protection, industrial filtration and decoration, and its durability and aesthetics make it an ideal choice in a variety of environments.',
        "Supply": 'Stock or customized',
        "picUrl": [
            '/static/stainlessSteelScreen/StainlessSteelWeldingMesh/1.jpg',
            '/static/stainlessSteelScreen/StainlessSteelWeldingMesh/2.jpg',
            '/static/stainlessSteelScreen/StainlessSteelWeldingMesh/3.jpg',
            '/static/stainlessSteelScreen/StainlessSteelWeldingMesh/4.jpg',
            '/static/stainlessSteelScreen/StainlessSteelWeldingMesh/5.png',
            '/static/stainlessSteelScreen/StainlessSteelWeldingMesh/6.png',
            '/static/stainlessSteelScreen/StainlessSteelWeldingMesh/7.png',
            '/static/stainlessSteelScreen/StainlessSteelWeldingMesh/8.png',
            '/static/stainlessSteelScreen/StainlessSteelWeldingMesh/9.png',
        ]
    },
    {
        "id": 4,
        "name": "Stainless Steel Embossed Mesh",
        "nameCH": '不锈钢轧花网',
        "Material": 'Stainless Steel301 、 302 、 304 、 304L 、 316 、 316L 、 321',
        "ProcessingMode": 'Woven',
        "No": 'YC001',
        "Type": 'Woven net',
        "ProductCharacteristics": 'Stainless steel mesh products have the characteristics of corrosion resistance, high strength, easy to clean and long service life.',
        "Size": [
            'Width 1000mm-Length 30m',
            'Width 1200mm-Length 30m',
            'Width 1500mm-Length 30m',
            'Width 1800mm-Length 30m',
            'Width 2000mm-Length 30m'
        ],
        "ProductUse": 'Stainless steel mesh is widely used in screening, filtration, protection, architectural decoration and other fields, because of its good corrosion resistance and durability and is favored.',
        "Supply": 'Stock or customized',
        "picUrl": [
            '/static/stainlessSteelScreen/StainlessSteelScreenEmbossing/1.jpg',
            '/static/stainlessSteelScreen/StainlessSteelScreenEmbossing/2.png',
            '/static/stainlessSteelScreen/StainlessSteelScreenEmbossing/3.png',
            '/static/stainlessSteelScreen/StainlessSteelScreenEmbossing/4.png',
            '/static/stainlessSteelScreen/StainlessSteelScreenEmbossing/5.png',
            '/static/stainlessSteelScreen/StainlessSteelScreenEmbossing/6.png',
        ]
    },
    {
        "id": 5,
        "name": "Stainless Steel Gas Liquid Mesh",
        "nameCH": '不锈钢气液网',
        "Material": 'Stainless Steel',
        "ProcessingMode": 'Woven',
        "No": 'YC001',
        "Type": 'Woven net',
        "ProductCharacteristics": 'Stainless steel gas liquid mesh has excellent high temperature resistance, corrosion resistance, stable structure, high filtration efficiency, is the ideal material for gas and liquid filtration, separation.',
        "Size": [
            'Width 20mm-Length 30m',
            'Width 100mm-Length 30m',
            'Width 300mm-Length 30m',
            'Width 500mm-Length 30m',
            'Width 1000mm-Length 30m'
        ],
        "ProductUse": 'Stainless steel gas liquid mesh is mainly used in chemical, petroleum, pharmaceutical industry gas liquid separation, filtration and fluid transport process, with its high efficiency filtration and chemical corrosion resistance.',
        "Supply": 'Stock or customized',
        "picUrl": [
            '/static/stainlessSteelScreen/StainlessSteelGasAndLiquidFilter/1.jpg',
            '/static/stainlessSteelScreen/StainlessSteelGasAndLiquidFilter/2.png',
            '/static/stainlessSteelScreen/StainlessSteelGasAndLiquidFilter/3.png',
            '/static/stainlessSteelScreen/StainlessSteelGasAndLiquidFilter/4.png',
            '/static/stainlessSteelScreen/StainlessSteelGasAndLiquidFilter/5.png',
            '/static/stainlessSteelScreen/StainlessSteelGasAndLiquidFilter/6.png',
        ]
    },
    {
        "id": 6,
        "name": "Stainless Steel Punching Mesh",
        "nameCH": '不锈钢冲孔网',
        "Material": 'Metal',
        "ProcessingMode": 'Punching',
        "No": 'YC001',
        "Type": 'Punching net',
        "ProductCharacteristics": 'Known for its evenly distributed aperture, good air permeability and light transmission performance and stable structural strength, circular mesh is widely used in screening, filtration, decoration and safety protection fields, showing high practicality and aesthetics.',
        "Size": [
            'Width 10mm-2000mm',
        ],
        "ProductUse": 'Due to its diverse pore size and high strength characteristics, round mesh is widely used in screening ore, solid particle screening operations, sound insulation, ventilation and aesthetic design in audio, decoration and construction fields, showing its flexibility and versatility in industrial and life applications.',
        "Supply": 'Stock or customized',
        "picUrl": [
            '/static/stainlessSteelScreen/StainlessSteelPunchingMesh/1.jpg',
            '/static/stainlessSteelScreen/StainlessSteelPunchingMesh/2.jpg',
            '/static/stainlessSteelScreen/StainlessSteelPunchingMesh/3.png',
            '/static/stainlessSteelScreen/StainlessSteelPunchingMesh/4.png',
            '/static/stainlessSteelScreen/StainlessSteelPunchingMesh/5.png',
            '/static/stainlessSteelScreen/StainlessSteelPunchingMesh/6.png',
            '/static/stainlessSteelScreen/StainlessSteelPunchingMesh/7.png',
        ]
    },
    {
        "id": 7,
        "name": "Decorative Net",
        "nameCH": '装饰网',
        "Material": 'Metal',
        "ProcessingMode": 'Crochet',
        "No": 'YC001',
        "Type": 'Decorative Net',
        "ProductCharacteristics": 'The product features of metal decorative mesh are mainly reflected in the following points: high-end beauty, strong durability, high flexibility, easy installation and maintenance, as well as good corrosion resistance and fire resistance. It is suitable for a variety of indoor and outdoor decoration and partition needs, and can enhance the modern sense and artistic atmosphere of the space.',
        "Size": [
            'Width 100mm-4000mm',
        ],
        "ProductUse": 'The purpose of metal decorative mesh curtains can be summarized in one sentence: they are used to create elegant space divisions, adjust light and enhance the beauty of interior design. They are commonly found in high-end hotels, office buildings, homes and stage decorations. They are both practical and decorative.',
        "Supply": 'Stock or customized',
        "picUrl": [
            '/static/stainlessSteelScreen/DecorativeNet/1.png',
            '/static/stainlessSteelScreen/DecorativeNet/2.jpg',
            '/static/stainlessSteelScreen/DecorativeNet/3.jpg',
            '/static/stainlessSteelScreen/DecorativeNet/4.jpg',
            '/static/stainlessSteelScreen/DecorativeNet/5.jpg',
            '/static/stainlessSteelScreen/DecorativeNet/6.jpg',
        ]
    },
    {
        "id": 8,
        "name": "Stainless Steel Rope Net",
        "nameCH": '不锈钢绳网',
        "Material": 'Metal',
        "ProcessingMode": 'Crochet',
        "No": 'YC001',
        "Type": 'Decorative Net',
        "ProductCharacteristics": 'Stainless steel rope mesh products are characterized by its high strength, corrosion resistance, high flexibility and long service life, suitable for a variety of complex environments for safety protection and decorative purposes.',
        "Size": [
            'Width 500mm-8000mm',
        ],
        "ProductUse": 'Stainless steel rope mesh is widely used in architectural decoration, security protection, zoo cages, green fences, etc. Its main functions include providing a high-strength protective barrier to prevent people or animals from crossing it, and at the same time, it has good weather resistance and aesthetic appearance, which can ensure safety while adapting to various environments and beautifying the space.',
        "Supply": 'Stock or customized',
        "picUrl": [
            '/static/stainlessSteelScreen/StainlessSteelRopeNet/1.jpg',
            '/static/stainlessSteelScreen/StainlessSteelRopeNet/2.jpg',
            '/static/stainlessSteelScreen/StainlessSteelRopeNet/3.png',
            '/static/stainlessSteelScreen/StainlessSteelRopeNet/4.jpg',
            '/static/stainlessSteelScreen/StainlessSteelRopeNet/5.jpg',
            '/static/stainlessSteelScreen/StainlessSteelRopeNet/6.jpg',
        ]
    },
]
/** 过滤网 Filter*/
const filter = [
    {
        "id": 1,
        "name": "Coffee Filter Mesh",
        "nameCH": '咖啡过滤网片',
        "Material": '304 316 stainless steel',
        "ProcessingMode": 'Stamping, weaving or welding, etc.',
        "No": 'YC002',
        "Type": 'filter',
        "ProductCharacteristics": 'Coffee filter mesh is a key component for filtering coffee grounds during the coffee brewing process, usually made of stainless steel, nylon and other materials, with a variety of specifications and features',
        "Size": [
            'round',
            'square',
            'conical',
        ],
        "ProductUse": 'Coffee filter mesh is a key component used to filter coffee grounds during the coffee brewing process. Coffee filters come in a variety of sizes and shapes to fit different brands and models of coffee makers or coffee machines. Common sizes include diameters ranging from a few centimeters to tens of centimeters, and shapes such as round, square, and conical. In addition, the mesh size of the filter mesh is also adjusted according to the usage requirements to ensure the best filtration effect.',
        "Supply": 'Stock or customized',
        "picUrl": [
            '/static/filter/CoffeeFilterMesh/1.png',
        ]
    },
    {
        "id": 2,
        "name": "Edge-wrapped Filter Mesh",
        "nameCH": '包边过滤网片',
        "Material": 'Stainless steel (e.g. 304, 304L, 316, 316L, etc.), black wire cloth, galvanized wire mesh and other metal materials',
        "ProcessingMode": 'Stamping, weaving or welding, etc.',
        "No": 'YC002',
        "Type": 'filter',
        "ProductCharacteristics": 'Wrapped Edge Filter Mesh Sheets are a widely used filtration element in a number of industrial applications and are characterized by specially treated edges for added strength and durability.',
        "Size": [
            'Dia:15-500mm',
        ],
        "ProductUse": 'Petrochemical industry: used for precision filtration to ensure product quality. Textile industry: used for impurity filtration in the front side of the spinning nozzle and in the textile raw liquid in the textile process of chemical fibers and man-made fibers. Mechanical equipment: such as filter presses, marine diesel engines and other machinery and equipment oil system filtration. Chemical industry: in the process of synthesis, evaporation, distillation and other processes, used as a filter element. Other industries: such as metallurgy, medicine, food and other industries, also has a wide range of applications.',
        "Supply": 'Stock or customized',
        "Attention": 'According to the material: stainless steel filter mesh, perforated mesh filter mesh and so on. |According to the processing technology: edge-wrapped filter wire mesh, spot-welded filter wire mesh and so on. |By shape: round filter wire mesh, rectangular filter wire mesh, oval filter wire mesh, ring filter wire mesh and so on.',
        "picUrl": [
            '/static/filter/EdgeWrappedFilterMesh/1.png',
        ]
    },
    {
        "id": 3,
        "name": "Sintered Felt|Sintered Filter Mesh",
        "nameCH": '烧结毡|烧结滤网',
        "Material": 'Metal',
        "ProcessingMode": 'High temperature sintering',
        "No": 'YC002',
        "Type": 'filter',
        "ProductCharacteristics": 'Sintered felt has the advantages of high temperature resistance, corrosion resistance, high precision filtration, washable and reusable, environmental protection and energy saving.',
        "Size": [
            'thicknesses:2-3mm',
        ],
        "ProductUse": 'Sintered felts are widely used in many fields such as industry, environmental protection, metallurgy, chemical industry, food and so on.',
        "Supply": 'Stock or customized',
        "Attention": 'Parameters such as sintering temperature and pressure need to be strictly controlled during the production process to ensure product quality and performance.',
        "picUrl": [
            '/static/filter/Sintered/1.png',
        ]
    },
    {
        "id": 4,
        "name": "Filter Cartridge",
        "nameCH": '过滤滤芯',
        "Material": 'Metal',
        "ProcessingMode": 'High temperature sintering',
        "No": 'YC002',
        "Type": 'filter',
        "ProductCharacteristics": 'High strength, high precision, heat resistance, long life, etc.',
        "Size": [
            'Folded cartridge',
            'Perforated cartridge'
        ],
        "ProductUse": 'Chemical industry: for the removal of impurities, solid particles and microorganisms, maintaining the integrity of production equipment and the consistency of product quality.Food and beverage industry: for clarifying liquids and filtering beverages and food products to ensure product hygiene and quality.Pharmaceutical industry: Used in the pharmaceutical production process for the filtration of laiquids and gases to ensure the purity and safety of medicines.Electronics industry: used for gas purification and liquid filtration in semiconductor manufacturing and electronic component production to prevent particles from damaging electronic components.Oil and gas industry: plays a key role in the treatment of crude oil and natural gas, removing solid impurities, particles and granules.Water and Wastewater Treatment: Used in the treatment of drinking and industrial water to remove suspended solids, bacteria and microorganisms.Other industries: such as the coatings and paints industry, mining and metal processing are also widely using sintered cartridges for particle and impurity removal.',
        "Supply": 'Stock or customized',
        "Attention": 'In order to maintain the filtration performance of the sintered cartridge, it should be cleaned and maintained regularly to avoid clogging and damage.',
        "picUrl": [
            '/static/filter/FilterCartridge/1.png',
            '/static/filter/FilterCartridge/2.png',
            '/static/filter/FilterCartridge/3.png',
            '/static/filter/FilterCartridge/4.png',
            '/static/filter/FilterCartridge/5.png',
            '/static/filter/FilterCartridge/6.png',
        ]
    },
    {
        "id": 5,
        "name": "Coffee Filter",
        "nameCH": '咖啡过滤器',
        "Material": '304 stainless steel',
        "ProcessingMode": 'Stamping, weaving or welding, etc.',
        "No": 'YC002',
        "Type": 'filter',
        "ProductCharacteristics": 'Coffee filter is an indispensable accessory in the coffee making process, used to separate the coffee grounds and coffee liquid to ensure the taste and purity of coffee. No filter paper required, stable water flow, reusable',
        "Size": [
            '100mm',
            '125mm',
            '115mm',
            '145mm',
        ],
        "ProductUse": 'Mainly used for hand brewing drip filter coffee, tea brewing, juice filtration and so on.',
        "Supply": 'Stock or customized',
        "picUrl": [
            '/static/filter/CoffeeFilter/1.png',
        ]
    },
    {
        "id": 6,
        "name": "Hop Tube",
        "nameCH": '啤酒过滤网筒',
        "Material": '304 stainless steel',
        "ProcessingMode": 'Stamping, weaving or welding, etc.',
        "No": 'YC002',
        "Type": 'filter',
        "ProductCharacteristics": 'Beer filter cartridges are generally made of metal wires woven together to form a mesh structure. Their shapes and sizes can be customized according to specific needs, and the common ones are cylindrical, basket type and so on. Some filter cartridges are also equipped with accessories such as lids and hanging chains for easy use and cleaning.',
        "Size": [
            '6 inches',
            '14 inches',
            '300mm',
            '330mm',
            '350mm'
        ],
        "ProductUse": 'The main function of the beer filter cartridge is to filter out suspended matter, imapurities and residue from the beer to improve the clarity and taste of the beer. It effectively removes hops, malt residue and other particles from the beer to ensure the purity and quality of the beer.',
        "Supply": 'Stock or customized',
        'Attention': 'Regularly clean and sanitize the beer filter cartridge to maintain its hygiene and filtration effect. During the cleaning process, avoid using hard objects to scrape the surface of the filter cartridge to avoid damaging its structure. After cleaning, the filter cartridge should be dried and stored in a dry and ventilated place to prevent rust and corrosion.',
        "picUrl": [
            '/static/filter/HopTube/1.png',
        ]
    },
    {
        "id": 7,
        "name": "Perforated Filter Cartridge",
        "nameCH": '冲孔过滤网筒',
        "Material": 'stainless steel',
        "ProcessingMode": 'Stamping, weaving or welding, etc.',
        "No": 'YC002',
        "Type": 'filter',
        "ProductCharacteristics": 'Good filtration performance: perforated filter mesh cartridge can play a uniform surface filtration performance for filtration particle size of 2-200um. Excellent resistance: characterized by good corrosion resistance, heat resistance, pressure resistance and abrasion resistance.',
        "Size": [
            '6 inches',
            '14 inches',
            '300mm',
            '330mm',
            '350mm'
        ],
        "ProductUse": 'Petrochemical industry: used in oil field pipeline filtration, refueling equipment, engineering machinery and equipment fuel filtration. Water treatment: It plays a filtering role in the equipment of water treatment industry. Pharmaceutical and food processing: in the field of pharmaceutical and food processing, perforated filter mesh cartridges also play an important role. Other industries: In addition, perforated filter mesh cartridges are also widely used in mining, machinery manufacturing and other industries.',
        "Supply": 'Stock or customized',
        'Attention': 'When shopping, you need to be clear about your needs and consider factors such as brand, price, and reviews in order to choose the best product for you.',
        "picUrl": [
            '/static/filter/PerforatedFilterCartridge/1.png',
        ]
    },
    {
        "id": 8,
        "name": "Woven Filter Cartridges",
        "nameCH": '编织过滤网筒',
        "Material": 'Stainless Steel Wire Mesh, Sintered Wire Mesh, Perforated Wire Mesh',
        "ProcessingMode": 'Stamping, weaving or welding, etc.',
        "No": 'YC002',
        "Type": 'filter',
        "ProductCharacteristics": 'The braided filter cartridge has the advantages of high filtration precision, good air permeability, stable structure, easy cleaning and maintenance. Its unique woven structure can effectively intercept impurities, while ensuring the smooth passage of fluids.',
        "Size": [
            'Support customization',
        ],
        "ProductUse": 'Industrial filtration: In chemical, petroleum, pharmaceutical and other industries, woven filter mesh cartridges are used to filter impurities in liquids or gases to ensure the purity and quality of products. Dust removal and purification: In the field of environmental protection, woven filter mesh cartridges are used in dust collectors, air purifiers and other equipment to effectively remove dust, particles and other pollutants in the air. Food processing: In the food processing industry, woven filter mesh cartridges can be used to filter impurities in raw materials and finished products to ensure food hygiene and safety. Other fields: In addition, woven filter mesh cartridges are widely used in water treatment, aerospace, automobile manufacturing and other fields.',
        "Supply": 'Stock or customized',
        'Attention': 'When shopping, you need to be clear about your needs and consider factors such as brand, price, and reviews in order to choose the best product for you.',
        "picUrl": [
            '/static/filter/WovenFilterCartridges/1.png',
        ]
    },
    {
        "id": 9,
        "name": "Single Layer Filter Cap",
        "nameCH": '单层过滤网帽',
        "Material": 'Stainless Steel',
        "ProcessingMode": 'Stamping, weaving or welding, etc.',
        "No": 'YC002',
        "Type": 'filter',
        "ProductCharacteristics": 'The braided filter cartridge has the advantages of high filtration precision, good air permeability, stable structure, easy cleaning and maintenance. Its unique woven structure can effectively intercept impurities while ensuring the smooth passage of fluids. Stainless steel filter caps are mainly made of stainless steel, and common stainless steel materials include 201, 304, 306, 316, 316L and so on. These materials have excellent corrosion resistance, high temperature resistance and mechanical strength, suitable for a variety of harsh working environment.',
        "Size": [
            '10mm', '15mm,', '25mm', '30mm', '80mm', '120mm',
        ],
        "ProductUse": 'Water treatment: In the filter pool of water supply projects, the screen cap is used as part of the backwashing system to help restore the normal operation of the filter pool and ensure the quality and quantity of the effluent water. Chemical industry: In all aspects of chemical production, strainer caps are used to filter, separate and purify various raw materials. Beverage production: In the production process of beer, fruit juice, milk drinks and tea drinks, etc., filter caps are used to remove impurities and insoluble substances in liquor or drinks to improve the quality and taste of the products. Automotive: In automobile engines and air-conditioning systems, filter caps are used to prevent impurities and particles from entering and protect the equipment from damage. Construction: In ventilation duct systems and household air purifiers, filter caps are used to remove harmful substances and dust from the air, keeping indoor air fresh and healthy.',
        "Supply": 'Stock or customized',
        'Attention': "INSTALLATION: The installation of single-layer stainless steel strainer caps depends on their application environment and system requirements. Typically, strainer caps need to be installed in accordance with the manufacturer's instructions to ensure a strong connection and reliable seal. Maintenance: Regular cleaning and inspection of the strainer cap is essential to ensure its proper functioning. When cleaning, appropriate cleaning agents and tools should be used to avoid damaging the surface and structure of the strainer cap. If the strainer cap is found to show signs of damage or degradation, it should be replaced in a timely manner to ensure proper operation of the system.",
        "picUrl": [
            '/static/filter/SingleLayerFilterCap/1.png',
        ]
    },
    {
        "id": 10,
        "name": "Stainless Steel Mesh Cover",
        "nameCH": '不锈钢网罩',
        "Material": 'Stainless Steel',
        "ProcessingMode": 'Braided Stamping',
        "No": 'YC002',
        "Type": 'filter',
        "ProductCharacteristics": 'Stainless steel mesh cover, made of high quality stainless steel with excellent corrosion resistance and strength, with precise and uniform mesh holes, ensures both good ventilation and light transmission performance and excellent protection and safety, suitable for kitchen utensils, decoration and industrial filtration.',
        "Size": [
            'Support processing by drawing Processing by sample',
        ],
        "ProductUse": 'Stainless steel mesh covers are widely used in splash guards for kitchen appliances, flame isolation for barbecue grills, filtration systems for air purifiers and exhaust fans, as well as industrial safety protection and decorative landscaping to meet diversified needs by virtue of their durability and aesthetics.',
        "Supply": 'Stock or customized',
        'Attention': "",
        "picUrl": [
        ]
    },
    {
        "id": 11,
        "name": "Stainless Steel Mesh Basket",
        "nameCH": '不锈钢过滤网篮',
        "Material": 'Stainless Steel',
        "ProcessingMode": 'Braided Stamping',
        "No": 'YC002',
        "Type": 'filter',
        "ProductCharacteristics": 'Main Material: Stainless steel wire mesh basket mesh baskets are mainly made of stainless steel materials such as 304, 304L, 316, 316L and so on. These materials have excellent corrosion resistance, high temperature resistance, mechanical strength and good processing performance.',
        "Size": [
            'Square', ' Round', ' Shaped',
        ],
        "ProductUse": "Food industry: Used for filtration and storage in the cooking process of pasta, meat barbecue, etc. to ensure the hygiene and quality of food. Pharmaceutical industry: used for filtration and storage of pharmaceutical raw materials and finished products in the pharmaceutical process, to ensure the purity and safety of drugs. Chemical industry: used in the filtration, separation and storage of chemicals to prevent impurities from affecting product quality. Water treatment industry: used in the water treatment process to filter suspended solids and impurities in water, improve the cleanliness of water. Daily life: used as flower baskets, fruit baskets, vegetable baskets and other living utensils to meet people's daily life needs.",
        "Supply": 'Stock or customized',
        'Attention': "Strong corrosion resistance: stainless steel has excellent corrosion resistance and can be used for a long time in various harsh environments. Good high temperature resistance: stainless steel wire mesh basket mesh basket can work in high temperature environment without deformation or damage. Easy to clean and maintain: stainless steel surface is smooth and not easy to adhere to impurities, easy to clean and long service life. Beautiful and generous: after electrolytic polishing process of stainless steel mesh basket mesh basket appearance bright as a mirror, beautiful and generous. Strong customization: can be customized according to the specific needs of production, to meet the needs of different industries and fields of use.",
        "picUrl": [
            '/static/filter/StainlessSteelMeshBasket/1.jpg',
            '/static/filter/StainlessSteelMeshBasket/2.png',
            '/static/filter/StainlessSteelMeshBasket/3.png',
            '/static/filter/StainlessSteelMeshBasket/4.png',
            '/static/filter/StainlessSteelMeshBasket/5.png',
        ]
    },
]
/**稀有金属网Rare Metal Mesh Net*/
const rareMetalMeshNet = [
    {
        "id": 1,
        "name": "Hastelloy Woven Wire Mesh",
        "nameCH": '哈氏合金编织网',
        "Material": 'Hastelloy',
        "ProcessingMode": 'Stamping, weaving or welding, etc.',
        "No": 'YC002',
        "Type": 'Woven Wire Mesh',
        "ProductCharacteristics": 'Hastelloy mesh has excellent corrosion and temperature resistance properties, even in severe chemical and high temperature environments, maintaining high strength and stability, making it the material of choice for screening and filtration applications in harsh conditions.',
        "Size": [
            'Width 1000mm-Length30m',
            'Width 1200mm-Length30m',
            'Width 1500mm-Length30m',
            'Width 1800mm-Length30m',
            'Width 2000mm-Length30m',
        ],
        "ProductUse": 'Hastelloy mesh is widely used in reactors and heat exchangers in the petrochemical, chemical and pharmaceutical industries, as well as in key components of nuclear power and aerospace equipment due to its excellent corrosion and high-temperature resistance attributes, demonstrating excellent adaptability in high-intensity corrosion and high-temperature environments.',
        "Supply": 'Stock or customized',
        "picUrl": [
            '/static/rareMetalMeshNet/HastelloyWovenWireMesh/1.jpg',
            '/static/rareMetalMeshNet/HastelloyWovenWireMesh/2.png',
            '/static/rareMetalMeshNet/HastelloyWovenWireMesh/3.png',
            '/static/rareMetalMeshNet/HastelloyWovenWireMesh/4.png',
            '/static/rareMetalMeshNet/HastelloyWovenWireMesh/5.png',
            '/static/rareMetalMeshNet/HastelloyWovenWireMesh/6.png',
        ]
    },
    {
        "id": 2,
        "name": "Monel Woven Wire Mesh",
        "nameCH": '蒙乃尔编织网',
        "Material": 'Monel',
        "ProcessingMode": 'Woven',
        "No": 'YC003',
        "Type": 'Woven Wire Mesh',
        "ProductCharacteristics": 'By combining the excellent properties of nickel and copper, Monel alloy mesh exhibits excellent corrosion resistance, high strength and thermal stability, especially in harsh environments, making it the preferred choice for highly corrosion-resistant screening and filtration materials.',
        "Size": [
            'Width 1000mm-Length30m',
            'Width 1200mm-Length30m',
            'Width 1500mm-Length30m',
            'Width 1800mm-Length30m',
            'Width 2000mm-Length30m',
        ],
        "ProductUse": 'Due to its unique properties, Monel mesh is widely used in anti-corrosion components of chemical equipment, submarine pipelines in the oil and gas industry, and high temperature and high pressure environments in the aerospace sector, demonstrating its value for reliable applications under extreme conditions.',
        "Supply": 'Stock or customized',
        "picUrl": [
            '/static/rareMetalMeshNet/MonelWovenWireMesh/1.png',
            '/static/rareMetalMeshNet/MonelWovenWireMesh/2.jpg',
            '/static/rareMetalMeshNet/MonelWovenWireMesh/3.png',
            '/static/rareMetalMeshNet/MonelWovenWireMesh/4.png',
            '/static/rareMetalMeshNet/MonelWovenWireMesh/5.png',
            '/static/rareMetalMeshNet/MonelWovenWireMesh/6.png',
        ]
    },
    {
        "id": 3,
        "name": "Nickel Chromium Alloy Woven Wire Mesh",
        "nameCH": '镍铬合金编织网',
        "Material": 'Nickel-chromium alloy',
        "ProcessingMode": 'Woven',
        "No": 'YC003',
        "Type": 'Woven Wire Mesh',
        "ProductCharacteristics": 'Nickel-chromium alloy mesh has good resistance to high temperature, oxidation and corrosion, high strength and good elasticity, and is commonly used in the production of heat-resistant parts, heating elements and aerospace and other fields.',
        "Size": [
            'Width 1000mm-Length30m',
            'Width 1200mm-Length30m',
            'Width 1500mm-Length30m',
            'Width 1800mm-Length30m',
            'Width 2000mm-Length30m',
        ],
        "ProductUse": 'Due to its excellent performance, Nichrome alloy mesh is widely used in industrial fields, such as making high-temperature-resistant stoves, heating elements, chemical processing equipment, parts for aviation and spacecraft, and battery components for electric vehicles.',
        "Supply": 'Stock or customized',
        "picUrl": [
            '/static/rareMetalMeshNet/NickelChromiumAlloyWovenWireMesh/1.png',
            '/static/rareMetalMeshNet/NickelChromiumAlloyWovenWireMesh/2.jpg',
            '/static/rareMetalMeshNet/NickelChromiumAlloyWovenWireMesh/3.jpg',
            '/static/rareMetalMeshNet/NickelChromiumAlloyWovenWireMesh/4.png',
            '/static/rareMetalMeshNet/NickelChromiumAlloyWovenWireMesh/5.png',
            '/static/rareMetalMeshNet/NickelChromiumAlloyWovenWireMesh/6.png',
            '/static/rareMetalMeshNet/NickelChromiumAlloyWovenWireMesh/7.png',
            '/static/rareMetalMeshNet/NickelChromiumAlloyWovenWireMesh/8.png',
            '/static/rareMetalMeshNet/NickelChromiumAlloyWovenWireMesh/9.png',
            '/static/rareMetalMeshNet/NickelChromiumAlloyWovenWireMesh/10.png',
        ]
    },
    {
        "id": 4,
        "name": "Titanium Mesh",
        "nameCH": '钛网',
        "Material": 'Titanium',
        "ProcessingMode": 'Woven',
        "No": 'YC003',
        "Type": 'Woven Wire Mesh',
        "ProductCharacteristics": 'Titanium mesh is a mesh product made of strong, corrosion-resistant material with excellent heat resistance, corrosion resistance and mechanical strength, which is widely used in many fields.',
        "Size": [
            'Width 1000mm-Length30m',
            'Width 1200mm-Length30m',
            'Width 1500mm-Length30m',
            'Width 1800mm-Length30m',
            'Width 2000mm-Length30m',
        ],
        "ProductUse": 'Titanium mesh is widely used in filtration, support, protection and structural components in many fields such as chemical, medical, aerospace and others.',
        "Supply": 'Stock or customized',
        "picUrl": [
            '/static/rareMetalMeshNet/TitaniumMesh/1.jpg',
            '/static/rareMetalMeshNet/TitaniumMesh/2.png',
            '/static/rareMetalMeshNet/TitaniumMesh/3.png',
            '/static/rareMetalMeshNet/TitaniumMesh/4.png',
            '/static/rareMetalMeshNet/TitaniumMesh/5.png',
        ]
    },
    {
        "id": 5,
        "name": "Tinned Copper Wire Mesh",
        "nameCH": '镀锡铜网',
        "Material": 'Copper',
        "ProcessingMode": 'Woven',
        "No": 'YC003',
        "Type": 'Woven Wire Mesh',
        "ProductCharacteristics": 'Tin-plated copper wire mesh has excellent electrical conductivity and corrosion resistance, and the tin layer on its surface effectively prevents oxidation, enhances the antioxidant property of the mesh and prolongs its service life.',
        "Size": [
            'Width 1000mm-Length30m',
            'Width 1200mm-Length30m',
            'Width 1500mm-Length30m',
            'Width 1800mm-Length30m',
            'Width 2000mm-Length30m',
        ],
        "ProductUse": 'Tinned copper mesh is widely used in electromagnetic shielding to protect electronic equipment from electromagnetic interference, and as a key component in cable manufacturing to enhance signal quality and durability. It also plays an important role in battery collectors, filtration systems and the decorative industry, utilizing its conductive, corrosion-resistant and aesthetic properties.',
        "Supply": 'Stock or customized',
        "picUrl": [
            '/static/rareMetalMeshNet/TinnedCopperWireMesh/1.png',
        ]
    },
    {
        "id": 6,
        "name": "Phosphor Bronze Woven Wire Mesh",
        "nameCH": '磷铜编织网',
        "Material": 'copper(II) phosphate',
        "ProcessingMode": 'Woven',
        "No": 'YC003',
        "Type": 'Woven Wire Mesh',
        "ProductCharacteristics": 'Phosphor bronze wire mesh, woven from alloy wires containing copper and tin, has excellent corrosion and abrasion resistance, as well as good non-magnetic and ductile properties, and is widely used in precision filtration, shielding and chemical resistant environments.',
        "Size": [
            'Width 300mm-Length140-180m',
        ],
        "ProductUse": 'Phosphor bronze mesh, also known as tin bronze mesh, is mainly used for screening all kinds of particles and powders as well as filtering liquids and gases, featuring non-magnetic, abrasion-resistant, acid and alkali resistant and good ductility.',
        "Supply": 'Stock or customized',
        "picUrl": [
            '/static/rareMetalMeshNet/TinnedCopperWireMesh/1.png',
        ]
    },
    {
        "id": 7,
        "name": "Brass Woven Wire Mesh",
        "nameCH": '黄铜编织网',
        "Material": 'brass',
        "ProcessingMode": 'Woven',
        "No": 'YC003',
        "Type": 'Woven Wire Mesh',
        "ProductCharacteristics": 'Brass mesh has good electrical conductivity, corrosion resistance and abrasion resistance, and is commonly used in the fields of filtration, shielding and decoration.',
        "Size": [
            'Width 1000mm-Length30m',
            'Width 1200mm-Length30m',
            'Width 1500mm-Length30m',
            'Width 1800mm-Length30m',
            'Width 2000mm-Length30m',
        ],
        "ProductUse": 'It is used for guardrail protection of highway, railroad, airfield, residential district, harbor dock, garden, rearing, livestock and so on. Brass wire mesh is mainly used for screening particles and powder, filtering liquid and gas, as well as electromagnetic shielding and decoration. With its corrosion and wear-resistant properties, it plays an important role in industry, electronics and art.',
        "Supply": 'Stock or customized',
        "picUrl": [
            '/static/rareMetalMeshNet/TinnedCopperWireMesh/1.png',
        ]
    },
    {
        "id": 8,
        "name": "Copper Woven Mesh",
        "nameCH": '紫铜编织网',
        "Material": 'Copper',
        "ProcessingMode": 'Woven',
        "No": 'YC003',
        "Type": 'Woven Wire Mesh',
        "ProductCharacteristics": '1. high electrical conductivity: copper mesh is made of high purity copper wire, usually with a pure copper content of up to 99.999%, so it has excellent electrical conductivity. 2. high thermal conductivity: due to the high thermal conductivity of copper, copper mesh is able to transfer heat quickly and is suitable for applications that require good heat transfer. 3. Non-magnetic: copper violet itself is non-magnetic, which means that copper violet mesh will not be affected by magnetic fields and is suitable for use in environments that are sensitive to magnetic fields.',
        "Size": [
            'Width 1000mm-Length30m',
            'Width 1200mm-Length30m',
            'Width 1500mm-Length30m',
            'Width 1800mm-Length30m',
            'Width 2000mm-Length30m',
        ],
        "ProductUse": 'The main uses of purple copper mesh include heat dissipation of electronic products, electromagnetic shielding, anti-radiation protection, signal isolation and purification. It is widely used in electronic equipment, electric power facilities, aerospace, information industry and military field of anti-electromagnetic interference.',
        "Supply": 'Stock or customized',
        "picUrl": [
            '/static/rareMetalMeshNet/TinnedCopperWireMesh/1.png',
        ]
    },
    {
        "id": 9,
        "name": "Tungsten Wire Mesh",
        "nameCH": '钨网',
        "Material": 'Tungsten',
        "ProcessingMode": 'Woven',
        "No": 'YC003',
        "Type": 'Woven Wire Mesh',
        "ProductCharacteristics": 'Pure tungsten mesh has a high melting point, excellent high temperature resistance and good electrical conductivity, and is suitable for heating elements and electrical contacts in high temperature environments.',
        "Size": [
            'Width 1000mm-Length30m',
            'Width 1200mm-Length30m',
            'Width 1500mm-Length30m',
            'Width 1800mm-Length30m',
            'Width 2000mm-Length30m',
        ],
        "ProductUse": 'Due to its high melting point, high temperature resistance and good electrical conductivity, tungsten mesh is mainly used in heating elements of high-temperature furnaces, electronic vacuum devices and the manufacture of components in the aerospace field.',
        "Supply": 'Stock or customized',
        "picUrl": [
            '/static/rareMetalMeshNet/TungstenWireMesh/1.jpg',
            '/static/rareMetalMeshNet/TungstenWireMesh/2.png',
            '/static/rareMetalMeshNet/TungstenWireMesh/3.jpg',
            '/static/rareMetalMeshNet/TungstenWireMesh/4.png',
            '/static/rareMetalMeshNet/TungstenWireMesh/5.png',
            '/static/rareMetalMeshNet/TungstenWireMesh/6.png',

        ]
    },
    {
        "id": 10,
        "name": "Silver Wire Mesh",
        "nameCH": '银网',
        "Material": 'Copper',
        "ProcessingMode": 'Woven',
        "No": 'YC003',
        "Type": 'Woven Wire Mesh',
        "ProductCharacteristics": 'Sterling silver woven mesh has extremely high electrical, thermal and ductile conductivity, as well as good resistance to corrosion and acidity and sterilization.',
        "Size": [
            'Width 1000mm-Length30m',
            'Width 1200mm-Length30m',
            'Width 1500mm-Length30m',
            'Width 1800mm-Length30m',
            'Width 2000mm-Length30m',
        ],
        "ProductUse": 'The silver mesh is mainly used in the fields of electronics, electricity, aerospace and battery manufacturing',
        "Supply": 'Stock or customized',
        "picUrl": [
            '/static/rareMetalMeshNet/SilverWireMesh/1.jpg',
            '/static/rareMetalMeshNet/SilverWireMesh/2.webp',
            '/static/rareMetalMeshNet/SilverWireMesh/3.webp',
            '/static/rareMetalMeshNet/SilverWireMesh/4.png',
            '/static/rareMetalMeshNet/SilverWireMesh/5.png',
            '/static/rareMetalMeshNet/SilverWireMesh/6.png',
            '/static/rareMetalMeshNet/SilverWireMesh/6.png',
        ]
    },
    {
        "id": 11,
        "name": "Molybdenum Wire Mesh",
        "nameCH": '钼网',
        "Material": 'Silver',
        "ProcessingMode": 'Woven',
        "No": 'YC003',
        "Type": 'Woven Wire Mesh',
        "ProductCharacteristics": 'Pure molybdenum mesh has a high melting point, excellent corrosion resistance and good thermal stability, and is suitable for making electronic components, high-temperature heating elements and filtering materials in chemical equipment.',
        "Size": [
            'Width 1000mm-Length30m',
            'Width 1200mm-Length30m',
            'Width 1500mm-Length30m',
            'Width 1800mm-Length30m',
            'Width 2000mm-Length30m',
        ],
        "ProductUse": 'It is used for guardrail protection of highways, railroads, airports, residential neighborhoods, port terminals, gardens, rearing, animal husbandry and so on. Molybdenum mesh is commonly used to make heating elements for high temperature furnaces, components for electronic vacuum equipment, and filtration and catalytic materials in the chemical industry due to its good resistance to high temperature, corrosion and electrical conductivity.',
        "Supply": 'Stock or customized',
        "picUrl": [
            '/static/rareMetalMeshNet/MolybdenumWireMesh/1.jpg',
            '/static/rareMetalMeshNet/MolybdenumWireMesh/3.png',
            '/static/rareMetalMeshNet/MolybdenumWireMesh/4.png',
            '/static/rareMetalMeshNet/MolybdenumWireMesh/5.png',
        ]
    },
]

/**其他*/
const otherProducts = [
    {
        "id": 30,
        "name": "Galvanized Razor Wire",
        "nameCH": '镀锌剃须刀铁丝网',
        "Material": 'Metal',
        "ProcessingMode": 'Crochet',
        "No": 'YC001',
        "Type": 'Decorative Net',
        "ProductCharacteristics": 'Stainless steel rope mesh products are characterized by its high strength, corrosion resistance, high flexibility and long service life, suitable for a variety of complex environments for safety protection and decorative purposes.',
        "Size": [
            'Width 500mm-8000mm',
        ],
        "ProductUse": 'Stainless steel rope mesh is widely used in architectural decoration, security protection, zoo cages, green fences, etc. Its main functions include providing a high-strength protective barrier to prevent people or animals from crossing it, and at the same time, it has good weather resistance and aesthetic appearance, which can ensure safety while adapting to various environments and beautifying the space.',
        "Supply": 'Stock or customized',
        "picUrl": [
            '/static/otherProducts/GalvanizedRazorWire/1.png',
            '/static/otherProducts/GalvanizedRazorWire/2.png',
            '/static/otherProducts/GalvanizedRazorWire/3.png',
            '/static/otherProducts/GalvanizedRazorWire/4.png',
            '/static/otherProducts/GalvanizedRazorWire/5.png',
            '/static/otherProducts/GalvanizedRazorWire/6.jpg',
        ]
    }
]

/**热销产品*/
const hotProduct = [
    {
        "id": 1,
        "name": "Stainless Steel Mesh",
        "nameCH": '不锈钢编织',
        "Material": 'Stainless Steel',
        "ProcessingMode": 'Woven',
        "No": 'YC001',
        "Type": 'Woven net',
        "ProductCharacteristics": 'Stainless steel mesh products have the characteristics of corrosion resistance, high strength, easy to clean and long service life.',
        "Size": [
            'Width 1000mm-Length 30m',
            'Width 1200mm-Length 30m',
            'Width 1500mm-Length 30m',
            'Width 1800mm-Length 30m',
            'Width 2000mm-Length 30m'
        ],
        "ProductUse": 'Stainless steel mesh is widely used in screening, filtration, protection, architectural decoration and other fields, because of its good corrosion resistance and durability and is favored.',
        "Supply": 'Stock or customized',
        "picUrl": [
            '/static/stainlessSteelScreen/StainlessSteelMesh/1.jpg',
            '/static/stainlessSteelScreen/StainlessSteelMesh/2.png',
            '/static/stainlessSteelScreen/StainlessSteelMesh/3.png',
            '/static/stainlessSteelScreen/StainlessSteelMesh/4.png',
            '/static/stainlessSteelScreen/StainlessSteelMesh/5.png',
        ]
    },
    {
        "id": 2,
        "name": "Stainless steel mat mesh",
        "nameCH": '不锈钢席型网',
        "Material": 'Stainless Steel',
        "ProcessingMode": 'Woven',
        "No": 'YC001',
        "Type": 'Woven net',
        "ProductCharacteristics": 'Stainless steel mesh products have the characteristics of corrosion resistance, high strength, easy to clean and long service life.',
        "Size": [
            'Width 1000mm-Length 30m',
            'Width 1200mm-Length 30m',
            'Width 1500mm-Length 30m',
            'Width 1800mm-Length 30m',
            'Width 2000mm-Length 30m'
        ],
        "ProductUse": 'Stainless steel mesh is widely used in screening, filtration, protection, architectural decoration and other fields, because of its good corrosion resistance and durability and is favored.',
        "Supply": 'Stock or customized',
        "picUrl": [
            '/static/stainlessSteelScreen/StainlessSteelMatMesh/1.jpg',
            '/static/stainlessSteelScreen/StainlessSteelMatMesh/2.png',
            '/static/stainlessSteelScreen/StainlessSteelMatMesh/3.png',
            '/static/stainlessSteelScreen/StainlessSteelMatMesh/4.png',
            '/static/stainlessSteelScreen/StainlessSteelMatMesh/5.png',
        ]
    },
    {
        "id": 3,
        "name": "Stainless steel welding mesh",
        "nameCH": '不锈钢电焊网',
        "Material": 'Stainless Steel',
        "ProcessingMode": 'Weld',
        "No": 'YC001',
        "Type": 'Weld net',
        "ProductCharacteristics": 'Stainless steel welding mesh, made of high-quality stainless steel materials, has the characteristics of corrosion resistance, stable structure, changeable shape and long-term use without frequent maintenance. It is a widely used, economical and durable metal mesh product.',
        "Size": [
            'Width 1000mm-Length 30m',
            'Width 1200mm-Length 30m',
            'Width 1500mm-Length 30m',
            'Width 1800mm-Length 30m',
            'Width 2000mm-Length 30m'
        ],
        "ProductUse": 'Stainless steel welding mesh is widely used in the fields of building reinforcement, fence protection, industrial filtration and decoration, and its durability and aesthetics make it an ideal choice in a variety of environments.',
        "Supply": 'Stock or customized',
        "picUrl": [
            '/static/stainlessSteelScreen/StainlessSteelWeldingMesh/1.jpg',
            '/static/stainlessSteelScreen/StainlessSteelWeldingMesh/2.jpg',
            '/static/stainlessSteelScreen/StainlessSteelWeldingMesh/3.jpg',
            '/static/stainlessSteelScreen/StainlessSteelWeldingMesh/4.jpg',
            '/static/stainlessSteelScreen/StainlessSteelWeldingMesh/5.png',
            '/static/stainlessSteelScreen/StainlessSteelWeldingMesh/6.png',
            '/static/stainlessSteelScreen/StainlessSteelWeldingMesh/7.png',
            '/static/stainlessSteelScreen/StainlessSteelWeldingMesh/8.png',
            '/static/stainlessSteelScreen/StainlessSteelWeldingMesh/9.png',
        ]
    }
]
export {
    stainlessSteelScreen,
    filter,
    rareMetalMeshNet,
    hotProduct,
    otherProducts
}