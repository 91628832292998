<template>
  <h1 class="title">About Us</h1>
  <el-card>
    <div class="grid-box">
    <div class="left-wrapper">
        <div class="text">
          We have been specialized in producing different types of woven wire mesh and
          further processed products for 20 years. Main products: Metal wire mesh: mat
          type mesh, welded mesh, special material wire mesh: copper mesh, duplex steel
          mesh, nickel mesh, titanium mesh, molybdenum mesh, tungsten mesh, silver mesh,
          iron chromium aluminum mesh, Hastelloy mesh, monel wire mesh. Further
          processing: filter mesh, filter mesh cylinder, metal filter element, stainless
          steel mesh basket, gas and liquid filter metal decorative mesh. With the
          excellent quality of high temperature resistance, corrosion resistance, acid and
          alkali resistance, and wear resistance, we have won deep praise from domestic
          and foreign customers.
        </div>
        <div class="text">
          We sincerely hope to provide high-quality products, preferential prices and
          timely delivery to every customer around the world. Welcome to contact us for a
          quote on wire mesh products. Looking forward to meeting you.
        </div>
    </div>
    <div class="right-wrapper">
      <img class="img" src="static/aboutUs/bg.jpg" alt="image" />
    </div>
  </div>

  </el-card>

</template>

<script setup></script>

<style lang="less" scoped>
.grid-box {
  display: grid;
  grid-template-columns: 5fr 3fr;
  padding: 40px 0;
}
.left-wrapper {
  grid-column: 2fr;
  margin-right: 30px;
  .text {
    margin: 0px 0 12px;
    font-size: 16px;
    text-indent: 2em;
  }
}
.img {
  border-radius: 8px;
  width: 100%;
}
</style>
