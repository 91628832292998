<template>
  <div class="title">{{ title }}</div>
  <div class="grids-box">
    <div>
      <div class="ProductCategories">Product Categories</div>
      <el-collapse v-model="activeNames" @change="handleChange" accordion>
        <el-collapse-item
          v-for="item of productsKinds"
          :title="item.name"
          :name="item.id"
          :key="item.key"
        >
          <template v-if="item.id === 1">
            <div
              class="p-item"
              v-for="item of stainlessSteelScreen"
              :key="item.id"
              @click="handleClick(item)"
              :class="{
                active: currentItem.name === item.name,
              }"
            >
              {{ item.name }}
            </div>
          </template>
          <template v-if="item.id === 2">
            <div
              class="p-item"
              v-for="item of filter"
              :key="item.id"
              @click="handleClick(item)"
              :class="{
                active: currentItem.name === item.name,
              }"
            >
              {{ item.name }}
            </div>
          </template>
          <template v-if="item.id === 3">
            <div
              class="p-item"
              v-for="item of rareMetalMeshNet"
              :key="item.id"
              @click="handleClick(item)"
              :class="{
                active: currentItem.name === item.name,
              }"
            >
              {{ item.name }}
            </div>
          </template>
          <template v-if="item.id === 4">
            <div
              class="p-item"
              v-for="item of otherProducts"
              :key="item.id"
              @click="handleClick(item)"
              :class="{
                active: currentItem.name === item.name,
              }"
            >
              {{ item.name }}
            </div>
          </template>
        </el-collapse-item>
      </el-collapse>
    </div>

    <div class="rigth-box" v-if="currentItem">
      <el-card>
        <div class="container">
          <div class="container-left" v-show="selectItemSrc">
            <div class="selected-img">
              <el-image
                :src="selectItemSrc"
                :preview-src-list="currentItem?.picUrl"
              ></el-image>
            </div>
            <div class="img-container">
              <span class="arrow" @click="handleScroll(120)"
                ><ArrowLeftBold width="20px"
              /></span>
              <ul class="img-list" ref="imgRef">
                <li
                  class="img"
                  :class="{
                    active: selectItemSrc == url,
                  }"
                  @click="selectItemSrc = url"
                  v-for="url of currentItem?.picUrl"
                  :key="url"
                >
                  <img :src="url" />
                </li>
              </ul>
              <span class="arrow" @click="handleScroll(-120)"
                ><ArrowRightBold width="20px"
              /></span>
            </div>
          </div>
          <div class="container-rigth">
            <h1>{{ currentItem?.name }}</h1>
            <el-divider></el-divider>
            <div class="label">Material</div>
            <div class="value">{{ currentItem?.Material }}</div>
            <div class="label">ProcessingMode</div>
            <div class="value">{{ currentItem?.ProcessingMode }}</div>
            <div class="label">Type</div>
            <div class="value">{{ currentItem?.Type }}</div>

            <div class="label">Supply</div>
            <div class="value">{{ currentItem?.Supply }}</div>
            <div class="label">SIZE</div>
            <template v-for="item of currentItem?.Size" :key="item">
              <el-tag>
                {{ item }}
              </el-tag>
            </template>
          </div>
        </div>
        <div class="label">ProductUse</div>
        <div class="value">{{ currentItem?.ProductUse }}</div>
        <div class="label">ProductCharacteristics</div>
        <div class="value">{{ currentItem?.ProductCharacteristics }}</div>
        <div class="label" v-if="currentItem?.Attention">Attention</div>
        <div class="value">{{ currentItem?.Attention }}</div>
      </el-card>
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from "vue";
import { productsKinds } from "@/components/config";
import { useRoute } from "vue-router";
import {
  stainlessSteelScreen,
  filter,
  rareMetalMeshNet,
  otherProducts,
} from "@/mock/products";

const route = useRoute();

const title = computed(() => {
  return productsKinds.find((item) => item.id == route.query.id)?.name;
});
const activeNames = ref(productsKinds[1].id);

const defaultItem = (() => {
  if (route.query.id == 1) {
    return stainlessSteelScreen[0];
  }
  if (route.query.id == 2) {
    return filter[0];
  }
  if (route.query.id == 3) {
    return rareMetalMeshNet[0];
  }
  if (route.query.id == 4) {
    return otherProducts[0];
  }
})();
const currentItem = ref(defaultItem);

const selectItemSrc = ref(defaultItem.picUrl[0]);

const imgRef = ref();

const handleClick = (item) => {
  currentItem.value = item;
  selectItemSrc.value = item.picUrl[0];
};

const handleScroll = (value) => {
  const scrollLeft = imgRef.value.scrollLeft;
  const left = scrollLeft - value;
  imgRef.value.scrollLeft = left < 0 ? 0 : left;
};
</script>

<style lang="less" scoped>
.grids-box {
  display: grid;
  grid-template-columns: 340px 1fr;
  grid-column-gap: 20px;
}
.ProductCategories {
  padding: 16px;
  margin-bottom: 12px;
  color: #fff;
  background-color: #fcac21;
}
:deep(.el-collapse-item__header) {
  padding-left: 16px;
}
.p-item {
  padding: 10px 30px;
  cursor: pointer;
  &.active,
  &:hover {
    background-color: #e5e5e5;
  }
}
.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
  .container-left {
    margin-right: 12px;
    overflow: hidden;
    .selected-img {
      width: 100%;
      height: 400px;
      display: flex;
      justify-content: space-around;
    }
    .img-container {
      justify-content: space-between;
      margin-top: 30px;
      display: flex;
      align-items: center;
    }
    .img-list {
      overflow: hidden;
      display: inline-flex;
      scroll-behavior: smooth;

      .img {
        display: inline-block;
        flex: none;
        width: 80px;
        height: 80px;
        border: 1px solid #e5e7eb;
        list-style: none;
        margin-right: 12px;
        border-radius: 4px;
        overflow: hidden;
        &.active {
          border-color: #fcac21;
          border-width: 2px;
        }
        img {
          width: 100%;
        }
      }
    }
  }
}
.label {
  &::before {
    content: "";
    display: inline-block;
    width: 4px;
    height: 20px;
    background-color: #f5ae00;
    vertical-align: bottom;
    margin-right: 8px;
  }
  margin-top: 1em;
  margin-bottom: 0.5em;
  font-weight: bold;
}
.value {
  color: #737373;
  text-indent: 2em;
}
:deep(.el-tag.el-tag--primary) {
  margin: 8px;
}
.arrow {
  &:active {
    opacity: 0.7;
  }
}
</style>
