import { createWebHistory, createRouter } from 'vue-router';
import HomePage from '../view/homePage/homePage'
import ProductCenter from '../view/productCenter/productCenter.vue'
import IndexPage from '../view/IndexPage/indexPage.vue';
import contactUs from '../view/contactUs/contactUs.vue';
import aboutUs from '../view/aboutUs/aboutUs.vue';
import newsInfo from '../view/newsInfo/newsInfo.vue';

const routes = [
    {
        path: '/',
        component: HomePage,
        redirect: '/index',
        children: [
            { path: '/index', component: IndexPage },
            { path: '/productCenter', component: ProductCenter },
            { path: '/aboutUs', component: aboutUs },
            { path: '/contactUs', component: contactUs },
            { path: '/newsInfo', component: newsInfo }
        ]
    },

]

export default createRouter({
    history: createWebHistory(),
    routes,
})