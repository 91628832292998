<template>
  <headerNav></headerNav>
  <main class="body-container">
    <router-view />
  </main>
  <footerCom></footerCom>
</template>

<script setup>
import { onMounted } from "vue";
import headerNav from "@/components/headerNav.vue";
import footerCom from "@/components/footerCom.vue";
import { useMookDataStore } from "@/store/pinia";
import { products } from "@/mock/products";

const store = useMookDataStore();

onMounted(async () => {
  store.products = products;
});
</script>

<style lang="less" scoped>
.body-container {
  max-width: 1440px;
  margin: auto;
  padding: 32px;
}
</style>
