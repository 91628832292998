import axios from "axios";

const request = axios.create({
    baseURL: '',
    timeout: 6000
})

request.interceptors.request.use((config) => {
    
    return config
}, (error) => {
    return Promise.reject(error)
})

export default request